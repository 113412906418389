import React from "react";
import "./About.css";
const aboutus = () => {
  return (
    <div className="about-head">
      <div className="about-desc">First decentralized Startup funding app</div>
    </div>
  );
};

export default aboutus;
